import React from "react";
import PropTypes from "prop-types";
import Edit from "../../components/dialog/edit.js";
import {
  FormCheckboxField,
  FormPhoneField,
  FormRadioField,
  FormSelectField,
  FormTextField,
  ReadOnlyHorizontalField
} from "../../components/dialog/formField";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import {I18n} from "../../i18n";
import {StoreContext} from "../../stores/context";
import classNames from "classnames";

class Item extends React.Component {
  static contextType = StoreContext;

  handleGetData = async (service, editorState) => {
    let [
      regionsList,
      employerTypesList
    ] = await Promise.all([
      service.GetRegions(),
      service.GetSelectOptions(
        "employer_types",
        editorState.employerTypeId
      )
    ]);

    return {
      regionsList,
      employerTypesList
    };
  }

  render() {
    const {i18n, serviceClass, lang} = {...this.props};
    const {...rest} = {i18n, ...this.props};
    const {classes} = {...this.props};

    return (
      <Edit
        i18n={i18n}
        serviceClass={serviceClass}
        handleGetData={this.handleGetData}
        {...rest}
        render={(fieldProps) => (
          <>
            <Grid container spacing={24} alignItems="flex-start">
              <Grid item container xs={12} md={6}  direction="column" spacing={24}>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <Grid container spacing={16}>
                      <Grid item xs={12}>
                        <Typography variant="h3">
                          {i18n.personalDetails}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        container
                        direction="column"
                        xs={12} md={6}
                        spacing={8}
                      >
                        <Grid item>
                          <FormTextField fieldName="name" i18n={i18n} {...fieldProps} />
                        </Grid>
                        <Grid item>
                          <FormRadioField
                            fieldName="gender"
                            selectList={[
                              {value: "MALE", label: "Male"},
                              {value: "FEMALE", label: "Female"},
                              {value: "N/A", label: "n/a"},
                            ]}
                            {...fieldProps}
                          />
                        </Grid>
                        <Grid item>
                          <FormTextField
                            fieldName="nameTranscription"
                            i18n={i18n}
                            {...fieldProps}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        container
                        direction="column"
                        xs={12} md={6}
                        spacing={8}
                      >
                        <Grid item>
                          <FormTextField fieldName="email" i18n={i18n} {...fieldProps} />
                        </Grid>
                        {fieldProps.isEditable && (
                          <Grid item>
                            <FormCheckboxField
                              fieldName="showCellPhone"
                              label="Show Cell Phone"
                              labelClassName={classes.phoneCell}
                              {...fieldProps}
                              i18n={i18n}
                            />
                          </Grid>
                        )}
                        <Grid item>
                          {fieldProps.formState.showCellPhone && (
                            <FormPhoneField
                              fieldName="cellPhone"
                              hideLabel={true}
                              {...fieldProps}
                              i18n={i18n}
                              renderInputProps={
                                fieldProps.formState.isTransient
                                  ? this.renderCheckButton
                                  : undefined
                              }
                            />
                          )}
                        </Grid>
                      </Grid>

                      <Grid item style={{marginTop: 30}}>
                        <ReadOnlyHorizontalField
                          value={fieldProps.formState.surveyRating}
                          label={i18n.surveyRating}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item  xs={12} md={6} >
                  {!fieldProps.formState.isTransient && (
                    <div>
                      <Typography variant="h3">
                        {I18n[lang].events.events}
                      </Typography>
                      <Table className={classes.inlineTable}>
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.idColumn}>{I18n[lang].events.id}</TableCell>
                            <TableCell>{I18n[lang].events.name}</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {fieldProps.formState.events.map((event, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell>{event.id}</TableCell>
                                <TableCell>
                                  {`${this.props.lang === "Eng" ? event.nameEn : event.nameUa}`}
                                </TableCell>
                                {fieldProps.isEditable &&
                                <TableCell>
                                  <a
                                    onClick={() =>
                                      fieldProps.action({
                                        fieldName: "disconnectItemFromEvent",
                                        value: event,
                                      })
                                    }
                                    href="#"
                                  >
                                    <Button
                                      size="small"
                                      variant="contained"
                                      className={classNames("button-cancel", [
                                        classes.buttonRemoveSmall,
                                      ])}
                                      color="primary"
                                    >
                                      <i/>
                                      {I18n[lang].events.deleteParticipant}
                                    </Button>
                                  </a>
                                </TableCell>}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </div>
                  )}
                </Grid>
              </Grid>

              <Grid item  xs={12} md={6} >
                <Paper className={classes.paper}>
                  <Grid container spacing={16}>
                    <Grid item xs={12}>
                      <Typography variant="h3">
                        {I18n[lang].participants.employer}
                      </Typography>
                    </Grid>
                    <Grid item  xs={12} md={6} >
                      <FormTextField
                        fieldName="position"
                        label={I18n[lang].common.position}
                        {...fieldProps}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} >
                    </Grid>
                    <Grid item xs={12} md={6} >
                      <FormTextField
                        fieldName="employerName"
                        label={I18n[lang].common.employerName}
                        {...fieldProps}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} >
                      <FormTextField
                        fieldName="employerAddress"
                        label={I18n[lang].common.employerAddress}
                        {...fieldProps}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} >
                      <FormSelectField
                        fieldName="employerTypeId"
                        label={I18n[lang].common.employerTypeEn}
                        selectList={fieldProps.formState.employerTypesList}
                        {...fieldProps}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} >
                      <FormSelectField
                        fieldName="regionId"
                        label={I18n[lang].common.oblastEn}
                        selectList={fieldProps.formState.regionsList}
                        isDisabled={fieldProps.formState.isRegionDisabled}
                        {...fieldProps}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </>
        )
        }
      />
    );
  }
};

Item.propTypes = {
  i18n: PropTypes.object.isRequired,
  serviceClass: PropTypes.any.isRequired,
};

export default Item;
