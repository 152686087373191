import React from "react";
import {
    Grid,
    Button,
    Typography,
    Modal,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogActions,
    DialogContent,
    AppBar,
    Toolbar,
    IconButton,
    Paper
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import PrintIcon from '@material-ui/icons/Print';
import DownloadIcon from '@material-ui/icons/PictureAsPdf';
import {withStyles} from "@material-ui/core/styles";
import Loader from "./loader";
//import styles from "../style/styles";
import html2pdf from "html2pdf-jspdf2";

import RegistryGraphs from "../pages/graphs/registry";
import {withRouter} from "react-router-dom";
import classNames from "classnames";

const styles = (theme) => ({
    root: {

    },
    appBar:{
        backgroundColor:"#4D64A9",
    },
    header:
        {
            flexGrow: 1,
        },
    h3: {
        color: "#6495ED",
        fontSize: "2rem",
        marginTop: "24px",
        fontWeight: "900",
        fontFamily:"Montserrat, sans-serif",

    },
    paper: {
        marginTop: "80px",
        width: "824px",
        marginLeft: "25%"
    },

    printContainer: {
        width: "100%",
        paddingLeft: "30px",

        paddingRight: "40px",

        "& p":
            {
                fontFamily:"Montserrat, sans-serif",

            }
    }

})

class Print extends React.Component {
    constructor(props) {
        super(props);
        this.state = {open: true};
    }

    state = {
        open: true,
    };

    handleClickOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.props.history.push("/graphs");

        this.setState({open: false});
    };

    handleCreatePdf = () => {
        this.setState({loading: true});
        var element = document.getElementById('PrintContainer');

        html2pdf(element, {
            pagebreak: {after: '.pageBreak' },
            jsPDF:        { unit: 'cm', format: 'a4', orientation: 'portrait' },
        });
        this.setState({loading: false});
    }

    handlePrint = () => {window.print()}


    prettyDate = (dateString) => {
        if (dateString) {
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            const parts = dateString.split("-");

            return monthNames[parts[1] - 1] + " " + parts[0];
        } else {
            return "";
        }
    }

    render() {
        const {classes, lang, i18n, i18nCommon, graphsFilter} = this.props;
        return (
            <>
                {this.state.loading && (
                    <Loader/>
                )}
                <Dialog
                    fullScreen
                    open={this.state.open}
                    onClose={this.handleClose}
                    className="printDialog"
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                                <CloseIcon/>
                            </IconButton>
                            <Typography variant="h6" color="inherit" className={classes.header}>
                                Print Preview
                            </Typography>
                            <Button color="inherit" onClick={this.handlePrint}>
                                <PrintIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                                Print
                            </Button>
                            <Button color="inherit" onClick={this.handleCreatePdf}>
                                <DownloadIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                                Download
                            </Button>
                        </Toolbar>
                    </AppBar>

                    <Paper className={classNames(classes.paper, "printPaper")}>
                        <div id="PrintContainer" className={classNames(classes.printContainer, "toPrint")}>

                            <h3 className={classes.h3}>U-LEAD Programme Events</h3>

                            <p>Period: {this.prettyDate(this.props.monthFrom)}
                                <span> - </span>
                                {this.prettyDate(this.props.monthTo)}
                            </p>

                            <p>Geography:
                                {this.props.regionsSelected.length > 0 && (
                                    <span> {this.props.regionsSelected.map((r, i) => (i > 0 ? ", " : "") + r.label)}</span>)}
                                {this.props.regionsSelected.length === 0 && (<span> All Ukraine</span>)}
                            </p>

                            {this.props.ownersSelected.length > 0 && (
                                <p>Owner: {this.props.ownersSelected.map((r, i) => (i > 0 ? ", " : "") + r.label)}</p>)}


                            <p>Event format:
                                {this.props.formatsSelected.length > 0 && (
                                    <span> {this.props.formatsSelected.map((r, i) => (i > 0 ? ", " : "") + r.label)}</span>)}
                                {this.props.formatsSelected.length === 0 && (<span> All events</span>)}
                            </p>
                            <RegistryGraphs graphsFilter={graphsFilter} user={this.props.user} {...this.props}
                                            forPrint={true}/>
                        </div>
                    </Paper>
                </Dialog>
            </>
        );
    }
}

export default withRouter(withStyles(styles)(Print));
