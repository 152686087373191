import React from "react";
import {
    TextField,
    InputLabel,
    Typography,
    FormControlLabel,
    Checkbox,
    RadioGroup,
    Radio,
    InputAdornment, withWidth,
} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import {Field} from "react-final-form";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import classNames from "classnames";
import {selectStyles} from "../../style/select-styles";
import {MuiPickersUtilsProvider, InlineDatePicker} from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import {isSmall} from "../../config"

import NumberFormat from "react-number-format";

const GetValueAndLabel = (item, lang) => {
    return {
        value: item.id,
        label:
            lang === "Eng"
                ? item.en
                    ? item.en
                    : item.nameEn
                : item.ua
                    ? item.ua
                    : item.nameUa,
        ...item,
    };
};

export const FormFileInput = (props) => {
    const {
        fieldName,
        i18n,
        label,
        isEditable,
        message,
        classes,
        formState,
        action,
        tabIndex,
        icon,
    } = props;
    const labelFinal = label ? label : i18n[fieldName];

    // const disabledClassNames = classNames("text-field", props.classes.disabled);

    let file = formState[fieldName];
    let fileName = file?.name;
    let fileSize = undefined;
    if (file) {
        fileSize = `(${(Number(file.size) / 1024 / 1024).toFixed(2)} MB)`;
    }

    let IconComponent;
    if (icon) {
        IconComponent = require(`@material-ui/icons`)[icon];
    }


    return isEditable ? (
        <Field name={fieldName}>
            {({input, meta}) => (
                <div className={classes.inputFile}>
                    <InputLabel>{labelFinal}</InputLabel>
                    <input
                        type="file"
                        id={`${fieldName}Input`}
                        name={fieldName}
                        onChange={(e) => action({fieldName, value: e.target.files[0]})}
                        inputProps={{tabIndex}}
                    />
                    <label htmlFor={`${fieldName}Input`} style={{display: "flex", alignItems: "center"}}>
                        {IconComponent && <IconComponent/>}{message}
                    </label>
                    {fileName || fileSize && (<><span>{fileName}</span><span>{fileSize && fileSize}</span></>)}

                    <p>max. 10 MB</p>
                    {meta.error && (
                        <Typography variant="caption" className={classes.error}>
                            {meta.error}
                        </Typography>
                    )}
                </div>
            )}
        </Field>
    ) : (
        <Typography>
            <h4>{labelFinal}</h4>
            <p>{formState[fieldName]}</p>
        </Typography>
    );
};

export const FormDateField = withWidth()((props) => {
    const {
        fieldName,
        i18n,
        label,

        isEditable,
        value,
        action,
        isDisabled,
        classes,
        formState,
        tabIndex,
        width
    } = props;
    const labelFinal = label ? label : i18n[fieldName];

    const popoverProps = isSmall(width)
        ? {
            anchorReference: "anchorPosition",
            anchorPosition: {top: 0, left: 0}
        }
        : null;

    // const disabledClassNames = classNames("text-field", props.classes.disabled);
    const currentValue = value ? value : formState ? formState[fieldName] : {};

    return isEditable ? (
        <Field name={fieldName}>
            {({input, meta}) => (
                <>
                    <InputLabel>{labelFinal}</InputLabel>
                    <div className={classNames(classes.calendar, classes.dateBlock)}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <InlineDatePicker
                                className={classNames(classes.dateField, classes.textField, {
                                    [classes.errorField]: meta.invalid,
                                })}
                                format="dd.MM.yyyy"
                                {...input}
                                onChange={(e) => {
                                    action({fieldName, value: e});
                                }}
                                value={currentValue}
                                disabled={isDisabled}
                                PopoverProps={popoverProps}
                                InputProps={{
                                    tabIndex,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <div className={classes.dataIcon}>
                                                <img
                                                    src="/images/icons/calendar.svg"
                                                    alt="calendar"
                                                    className="date-icon"
                                                />
                                            </div>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </>
            )}
        </Field>
    ) : (
        <div>
            <InputLabel>{labelFinal}</InputLabel>
            <Typography varian="body2">{currentValue}</Typography>
        </div>
    );
});

export const FormNumberField = (props) => {
    const {
        fieldName,
        i18n,
        label,
        isEditable,
        value,
        action,
        isDisabled,
        formState,
        classes,
        hideLabel,
        placeholder,
        onChange,
        tabIndex,
    } = props;
    const labelFinal = label ? label : i18n[fieldName];
    // const disabledClassNames = classNames("text-field", props.classes.disabled);

    // value or formstate[fieldName] - but never undefined!
    const currentValue = value
        ? value
        : formState
            ? formState[fieldName] ?? ""
            : "";

    let currentOnChange =
        onChange ??
        ((e) => {
            action({fieldName, value: e.currentTarget.value}, true);
        });

    return isEditable ? (
        <Field name={fieldName}>
            {({input, meta}) => (
                <div>
                    {!hideLabel && <InputLabel>{labelFinal}</InputLabel>}
                    <TextField
                        type="number"
                        className={classNames(
                            classes.textField, classes.numberInput,
                            {[classes.errorField]: meta.invalid},
                            {disabled: isDisabled}
                        )}
                        variant="outlined"
                        // helperText={meta.touched && meta.error ? meta.error : ' '}
                        disabled={isDisabled}
                        value={currentValue}
                        onChange={currentOnChange}
                        placeholder={placeholder}
                        inputProps={{tabIndex}}
                    />
                </div>
            )}
        </Field>
    ) : (
        <ReadOnlyField label={labelFinal} value={currentValue}/>
    );
};

export const FormTextField = (props) => {
    const {
        fieldName,
        i18n,
        label,
        isEditable,
        value,
        action,
        isDisabled,
        formState,
        classes,
        hideLabel,
        placeholder,
        onChange,
        tabIndex,
    } = props;
    const labelFinal = label ? label : i18n[fieldName];
    // const disabledClassNames = classNames("text-field", props.classes.disabled);

    // value or formstate[fieldName] - but never undefined!
    const currentValue = value
        ? value
        : formState
            ? formState[fieldName] ?? ""
            : "";

    let currentOnChange =
        onChange ??
        ((e) => {
            action({fieldName, value: e.currentTarget.value}, true);
        });

    return isEditable ? (
        <Field name={fieldName}>
            {({input, meta}) => (
                <div>
                    {!hideLabel && <InputLabel>{labelFinal}</InputLabel>}
                    <TextField
                        className={classNames(
                            classes.textField,
                            {[classes.errorField]: meta.invalid},
                            {disabled: isDisabled}
                        )}
                        variant="outlined"
                        // helperText={meta.touched && meta.error ? meta.error : ' '}
                        disabled={isDisabled}
                        value={currentValue}
                        onChange={currentOnChange}
                        placeholder={placeholder}
                        inputProps={{tabIndex}}
                    />
                </div>
            )}
        </Field>
    ) : (
        <ReadOnlyField label={labelFinal} value={currentValue}/>
    );
};

export const FormLabel = (props) => {
    const {fieldName, i18n, label} = props;
    const labelFinal = label ? label : i18n[fieldName];
    return (
        <InputLabel className={props.className}>
            {labelFinal}
            {props.withColon ? ":" : ""}
        </InputLabel>
    );
};

export const ReadOnlyField = (props) => {
    return (
        <div>
            <InputLabel>{props.label}</InputLabel>
            {!props.value || props.value === "" ? (
                <Typography varian="body2">
                    <br/>
                </Typography>
            ) : (
                <Typography varian="body2">{props.value}</Typography>
            )}
        </div>
    );
};

export const ReadOnlyHorizontalField = (props) => {
    return (
        <div style={{display: "flex", alignItems: "center"}}>
            <InputLabel style={{marginRight: 7}}>{props.label}:</InputLabel>
            <Typography varian="body2" style={{fontWeight: 600}}>
                {props.value ? props.value.toFixed(1) : 0}
            </Typography>
        </div>
    );
};

export const FormPhoneField = (props) => {
    const {
        fieldName,
        i18n,
        label,
        isEditable,
        value,
        action,
        isDisabled,
        formState,
        classes,
        hideLabel,
        tabIndex,
    } = props;
    const labelFinal = label ? label : i18n[fieldName];
    // const disabledClassNames = classNames("text-field", props.classes.disabled);

    // value or formstate[fieldName] - but never undefined!
    const currentValue = value
        ? value
        : formState
            ? formState[fieldName] ?? ""
            : "";

    return isEditable ? (
        <Field name={fieldName}>
            {({input, meta}) => (
                <div>
                    {!hideLabel && <InputLabel>{labelFinal}</InputLabel>}
                    <NumberFormat
                        disabled={isDisabled}
                        value={currentValue}
                        onValueChange={(values) => {
                            action({fieldName, value: values.formattedValue}, true);
                        }}
                        customInput={TextField}
                        variant="outlined"
                        format="+38##########"
                        mask="_"
                        className={classNames(
                            classes.textField,
                            {[classes.errorField]: meta.invalid},
                            {disabled: isDisabled}
                        )}
                        InputProps={
                            props.renderInputProps
                                ? {...props.renderInputProps(action, formState), tabIndex}
                                : {tabIndex}
                        }
                    />
                </div>
            )}
        </Field>
    ) : (
        <div>
            <InputLabel>{labelFinal}</InputLabel>
            <Typography varian="body2">{currentValue}</Typography>
        </div>
    );
};

export const FormTextAreaField = (props) => {
    const {
        fieldName,
        i18n,
        label,
        isEditable,
        value,
        action,
        formState,
        classes,
        hideLabel,
        tabIndex,
    } = props;
    const labelFinal = label ? label : i18n[fieldName];
    const currentValue =
        (value ? value : formState ? formState[fieldName] : "") ?? "";

    return isEditable ? (
        <Field name={fieldName}>
            {({input, meta}) => (
                <div>
                    {!hideLabel && <InputLabel>{labelFinal}</InputLabel>}
                    <TextField
                        // label={labelFinal}
                        className={classNames(classes.textField, {
                            [classes.errorField]: meta.invalid,
                        })}
                        variant="outlined"
                        multiline
                        rows="1"
                        rowsMax="20"
                        inputProps={{maxLength: "4000", tabIndex}}
                        {...input}
                        value={currentValue}
                        onChange={(e) => {
                            action({fieldName, value: e.currentTarget.value}, true);
                        }}
                    />
                </div>
            )}
        </Field>
    ) : (
        <div>
            <InputLabel>{labelFinal}</InputLabel>
            <Typography varian="body2">{currentValue}</Typography>
        </div>
    );
};

export const FormCheckboxField = (props) => {
    const {
        classes,
        fieldName,
        i18n,
        label,
        isEditable,
        value,
        formState,
        action,
        isDisabled,
        tabIndex,
    } = props;

    const labelFinal = label ? label : i18n[fieldName];
    const currentValue = value
        ? value
        : formState
            ? formState[fieldName] ?? false
            : false;

    return (
        <Field name={fieldName} type="checkbox">
            {({input, meta}) => (
                <FormControlLabel
                    label={labelFinal}
                    className={props.labelClassName ?? classes.checkboxLabel}
                    control={
                        <Checkbox
                            {...input}
                            inputProps={{tabIndex}}
                            name={fieldName}
                            disabled={isDisabled || !isEditable}
                            checked={currentValue}
                            onChange={(e) => {
                                action({fieldName, value: e.target.checked});
                            }}
                        />
                    }
                />
            )}
        </Field>
    );
};

export const FormSelectField = withWidth()((props) => {
    const {
        fieldName,
        classes,
        i18n,
        label,
        isEditable,
        // item,
        action,
        isDisabled,
        selectList,
        value,
        formState,
        lang,
        tabIndex,
        formatGroupLabel,
        grouping,
        width
    } = props;
    const labelFinal = label ? label : i18n[fieldName];

    let list = selectList
        ? selectList
        : formState
            ? formState[`${fieldName}List`]
            : [];
    if (list) {
        list = list.map((x) => GetValueAndLabel(x, lang));
    } else {
        list = [];
    }

    const selectedValue = value ? value : formState ? formState[fieldName] : {};

    let selection;
    if (grouping) {
        for (let group of list) {
            for (let option of group.options) {
                if (Number(option.value) === Number(selectedValue)) {
                    selection = option;
                    break;
                }
            }

            if (selection) {
                break;
            }
        }
    } else {
        selection = list.find(
            (x) =>
                x.id === (selectedValue?.id ? selectedValue.id : selectedValue) ||
                Number(x.value) ===
                (selectedValue?.id ? selectedValue.id : selectedValue)
        );
    }

    return isEditable ? (
        <Field name={fieldName}>
            {({input, meta}) => (
                <>
                    <InputLabel className={classNames(classes.label)}>
                        {label ? label : i18n[fieldName]}
                    </InputLabel>
                    <Select
                        tabIndex={tabIndex}
                        className={classNames({
                            errorSelect: meta.invalid,
                            [classes.groupSelect]: grouping,
                        })}
                        styles={selectStyles(width)}
                        closeMenuOnSelect={true}
                        components={makeAnimated()}
                        {...input}
                        formatGroupLabel={formatGroupLabel}
                        options={list}
                        helperText={meta.touched && meta.error ? meta.error : " "}
                        value={selection}
                        isDisabled={isDisabled}
                        onChange={(e) => {
                            action({fieldName, value: e.value});
                        }}
                    />
                </>
            )}
        </Field>
    ) : (
        <div>
            <InputLabel>{labelFinal}</InputLabel>
            <Typography varian="body2">{selection?.label}</Typography>
        </div>
    );
});

export const FormMultiSelectField = withWidth()((props) => {
    const {
        lang,
        fieldName,
        classes,
        i18n,
        label,
        isEditable,
        // item,
        action,
        selectList,
        value,
        formState,
        hideLabel,
        tabIndex,
        width,
    } = props;
    const labelFinal = label ? label : i18n[fieldName];

    let list = selectList
        ? selectList
        : formState
            ? formState[`${fieldName}List`]
            : [];
    if (list) {
        list = list.map((x) => GetValueAndLabel(x, lang));
    } else {
        list = [];
    }

    const curentValues = value ? value : formState ? formState[fieldName] : [];
    let selection;
    if (curentValues) {
        //selectedValue = selectedValue.map((x) => GetValueAndLabel(x, lang));
        selection = list
            .filter((x) => curentValues.find((y) => y.id === x.id))
            .map((x) => GetValueAndLabel(x, lang));
    } else {
        selection = [];
    }

    return isEditable ? (
        <Field name={fieldName}>
            {({input, meta}) => (
                <>
                    {!hideLabel && (
                        <InputLabel className={classNames(classes.label)}>
                            {label ? label : i18n[fieldName]}
                        </InputLabel>
                    )}
                    <Select
                        inputProps={{tabIndex}}
                        isClearable={false}
                        className={classNames({
                            errorSelect: meta.invalid,
                        })}
                        styles={selectStyles(width)}
                        closeMenuOnSelect={false}
                        components={makeAnimated()}
                        {...input}
                        onChange={(e) => {
                            action({fieldName, value: e});
                        }}
                        isMulti
                        options={list}
                        value={selection}
                    />
                </>
            )}
        </Field>
    ) : (
        <div>
            <InputLabel>{labelFinal}</InputLabel>
            <Typography varian="body2">
                {selection?.map((x) => x.label).join(", ")}
            </Typography>
        </div>
    );
});

export const FormRadioField = (props) => {
    const {
        fieldName,
        classes,
        i18n,
        label,
        isEditable,
        action,
        value,
        formState,
        tabIndex,
    } = props;
    const labelFinal = label ? label : i18n[fieldName];

    const selectedValue = value
        ? value
        : formState
            ? formState[fieldName] ?? ""
            : "";

    return isEditable ? (
        <Field name={fieldName}>
            {({input, meta}) => {
                return (
                    <RadioGroup
                        inputProps={{tabIndex}}
                        row
                        aria-label={labelFinal}
                        name={fieldName}
                        className={classNames(classes.group, {
                            [classes.groupError]: meta.invalid,
                        })}
                        value={selectedValue}
                        onChange={(e) => {
                            action({fieldName, value: e.target.value});
                        }}
                    >
                        <FormControlLabel
                            value="MALE"
                            control={<Radio color="primary"/>}
                            label="Male"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="FEMALE"
                            control={<Radio color="primary"/>}
                            label="Female"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="n/a"
                            control={<Radio color="primary"/>}
                            label="N/A"
                            labelPlacement="end"
                        />
                    </RadioGroup>
                );
            }}
        </Field>
    ) : (
        <div>
            <InputLabel>{labelFinal}</InputLabel>
            <Typography varian="body2">{selectedValue}</Typography>
        </div>
    );
};
