import React, {createRef} from "react";
import {config} from "../../style/config.js";
import {observer} from "mobx-react";
import {
    Grid,
    Button,
    Typography,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    List,
    ListItem,
    Card,
    CardContent,
    CardActions,
    Hidden, TableSortLabel
} from "@material-ui/core";
import {Close, InsertDriveFile} from "@material-ui/icons";
import {withStyles} from "@material-ui/core/styles";
import {GIZButton, GIZSwitch, ResponsiveTable} from "../../components";
import withRoot from "../../withRoot";
import {StoreContext} from "../../stores/context";
import fetchData from "../../utils/fetch";
import downloadFile from "../../utils/fetchFile";
import fetchBase64 from "../../utils/fetchBase64";
import {Link, Redirect} from "react-router-dom";
import {Doughnut} from "react-chartjs-2";
import {each, orderBy, isInteger} from "lodash";
import Loader from "../../components/loader";
import {I18n} from "../../i18n/";
import classNames from "classnames";
import categoryCaptions from "../../utils/categoryCaptions";
import notify from "../../utils/notifier";
import {preregistrationRoute} from "../../utils/routes";
import {Breakpoints} from "../../config";

const styles = (theme) => ({
    root: {
        textAlign: "left",
        width: "94%",
        margin: "30px 3%",
    },
    button: {
        "&.disabled": {
            opacity: "0.3",
        },
    },
    modal: {
        position: "relative",
        maxWidth: theme.spacing.unit * 150,
        width: "94%",
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        margin: "3% auto",
        height: "90vh",
        overflowY: "auto",
        backgroundColor: "#f7f9fb",
        borderRadius: "15px",
        border: "0",
        outline: "0",
        "& a": {
            textDecoration: "none",
        },
        [theme.breakpoints.down(Breakpoints.md)]: {
            margin: '0',
            width: "100%!important",
            height: "100%!important",
            borderRadius: "0!important",
            paddingLeft: "16px",
            paddingRight: "16px",
        },
    },
    errorModal: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
        width: "400px",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: "none",
    },
    textField: {
        width: "100%",
        marginBottom: "20px",
    },
    alignRight: {
        textAlign: "right",
    },
    data: {
        "& h4": {
            color: "#6495ed",
            fontWeight: "100",
            fontSize: "19px",
            marginBottom: "0",
            marginTop: "10px",
            [theme.breakpoints.down(Breakpoints.md)]: {
                color: config.small.label.color,
                fontWeight: config.small.label.fontWeight,
                fontSize: config.small.label.fontSize,
                marginTop:"0",
                marginBottom:"10px",
            }
        },
        "& p": {
            color: "#5f6f80",
            fontSize: "13px",
            marginTop: "0",
            [theme.breakpoints.down(Breakpoints.md)]: {
                color: config.small.data.color,
                fontWeight: config.small.data.fontWeight,
                fontSize: config.small.data.fontSize,
                marginBottom:"20px",
            }
        },
    },
    imageFullScreen: {
        position: "fixed",
        width: "100vw",
        height: "100vh",
        left: 0,
        top: 0,
        zIndex: "99999",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
            maxHeight: "100vh",
            maxWidth: "90vw",
        },
        "& a": {
            position: "absolute",
            top: "20px",
            right: "20px",
            fontSize: "30px",
            fontWeight: "bold",
            color: "#fff",
        },
    },
    image: {
        width: "250px",
        cursor: "pointer",
    },
    table: {
        maxWidth: "100%",
        minWidth: 700,
        textAlign: "center",
        "& thead th:first-child": {
            paddingLeft: "15px",
        },
        "& thead th": {
            fontSize: "22px",
        },
        "& tbody td:first-child": {
            paddingLeft: "15px",
        },
        "& tbody tr": {
            verticalAlign: "top",
        },
        "& tbody td": {
            paddingTop: "20px",
        },
    },
    charts: {
        padding: "0 0 40px",
        "& h4": {
            textAlign: "center",
            marginBottom: "30px",
        },
    },
    buttons: {
        "& svg": {
            fontSize: "16px",
        },
        "& button": {
            fontSize: "12px",
            marginRight: "10px",
            marginBottom: "9px",
            padding: "8px",
            borderRadius: "6px",
        },
        "& button span": {
            minWidth: "auto",
            justifyContent: "flex-start",
        },
    },
    buttonYellow: {
        backgroundColor: "#FDBB2D",
        "&:hover": {
            backgroundColor: "#FDBB2D",
            opacity: "0.9",
        },
    },
    blockLink: {
        fontSize: "14px",
        color: "#5f6f80",
        fontWeight: "700",
        "&:hover": {
            textDecoration: "underline",
        },
    },
    hromada: {
        marginBottom: "50px",
        "& table": {
            minWidth: "0",
            marginTop: "15px",
        },
    },
    titleBlue: {
        fontSize: config.pageHeader,
        fontWeight: "700",
        color: "#88adf0",
        textTransform: "uppercase",
        lineHeight: "1",
        margin: "0",
        [theme.breakpoints.down(Breakpoints.md)]: {
            fontSize: config.small.pageHeader,
            lineHeight: "29px",
        }
    },
    titleYellow: {
        fontSize: config.pageHeader,
        fontWeight: "700",
        color: "#fdbb2d",
        textTransform: "uppercase",
        lineHeight: "1",
        margin: "8px 0 0 0px",
        [theme.breakpoints.down(Breakpoints.md)]: {
            fontSize: config.small.pageHeader,
            lineHeight: "29px",
        }
    },
    participantsRegistered: {
        padding: "30px",
        backgroundColor: "#fff",
        marginBottom: "30px",
    },
    linkReport: {
        display: "flex",
        alignItems: "center",
        color: "#5f6f80",
        lineHeight: "1",
        "& svg": {
            fontSize: "18px",
            color: "#0275d8",
            marginRight: "3px",
        },
    },
    participantActionCell: {
        display: "flex",
        justifyContent: "flex-end",
    },
    participantAction: {
        marginRight: "6px",
    },
    switch: {
        minHeight: "48px",
        "& span":
            {
                fontWeight: "700",
            },
    },
    tableCard: {
        width: "100%",

    },
    cardTable: {},
    cardActions: {
        display: "flex",
        justifyContent: "flex-end",
        "&  a i": {
            marginRight: "0 !important",
        },
        "&  a": {
            height: "24px",
            alignItems: "center",
            justifyContent: "center",

            display: "inline-flex",
            color: "#fff",
            fontSize: "12px",
            minHeight: "25px",
            lineHeight: "25px",
            backgroundColor: "#6495ed",
            padding: "6px 12px",
            margin: 0,

        },
        "&  a.edit": {
            backgroundColor: "#6495ed !important",
            "&:hover": {
                backgroundColor: "#5486e0 !important",
            },
        },
        "&  a.view": {
            backgroundColor: "#add8e6",
            "&:hover": {
                backgroundColor: "#9bcfe0",
            },
        },

        "& a i": {
            display: "block",
            width: "16px",
            height: "16px",
            marginRight: "9px",
        },
        "& a.view": {
            "& i": {
                width: "19px",
                background:
                    "url(/images/icons/icon-action-view.svg) no-repeat 0 center / 19px 20px",
            },
        },
        "& a.edit": {
            backgroundColor: "#6495ed !important",
            "& i": {
                top: "-2px",
                background:
                    "url(/images/icons/icon-action-edit.svg) no-repeat 0 0 / 20px 20px",
            },
        },
        "& a.delete": {
            backgroundColor: "#fe8368 !important",
            "& i": {
                background:
                    "url(/images/icons/icon-action-delete.svg) no-repeat 0 0 / 14px 14px",
            },
        },

        "&  a.delete": {
            backgroundColor: "#fe8368 !important",
            "&:hover": {
                backgroundColor: "#f67a5f !important",
            },
        },

        "& a:first-child": {
            backgroundColor: "#add8e6",
            borderRadius: "3px 0 0 3px",
        },
        "& a:last-child": {
            backgroundColor: "#fe8368",
            borderRadius: "0 3px 3px 0",
        },


    },
    cardTableHeader: {
        fontWeight: 900,
        borderStyle: "none",
    },

});

const genderDistribution = {
    labels: ["Male", "Female", "Unknown"],
    datasets: [
        {
            data: [],
            backgroundColor: [
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 99, 132, 0.2)",
                "rgba(88, 84, 215, 0.2)",
            ],
            borderColor: [
                "rgba(54, 162, 235, 1)",
                "rgba(255,99,132,1)",
                "rgba(88, 84, 215, 1)",
            ],
            borderWidth: 1,
        },
    ],
};

const employerLevelDistribution = {
    labels: [],
    datasets: [
        {
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1,
        },
    ],
};

const employerTypeDistribution = {
    labels: [],
    datasets: [
        {
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1,
        },
    ],
};

class ItemView extends React.Component {
    static contextType = StoreContext;

    state = {
        loading: true,
        item: null,
        open: true,
        isPhotoFull: false,
        photoFullBase64: null,
        photoPreviewBase64: null,
        survey_blank: true,
        list_participants: true,
        list_empty_participants: true,
        print_info: true,
        createSurveyProcessing: false,
        listPrereg: true,
    };

    dynamicColor = () => {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        return [r, g, b];
    };

    constructor(props) {
        super(props);
        this.fileInputRef = createRef();
    }

    componentDidMount() {
        const {item} = this.props;
        if (item && item.id) {
            this.onItemLoad(item);
        }
        this.getData(this.props.id);
    }

    onItemLoad(item) {
        if (!item || item.error || isInteger(item)) {
            this.setState({open: false, loading: false});
            alert("Event not found");
            return;
        }
        this.setEventStats(item);
        this.setState({item: item, loading: false});
        this.checkSurveyExists(item);

        if (item.eventFotoPreviewUrl) {
            fetchBase64(item.eventFotoUrl, (data) => {
                this.setState({photoPreviewBase64: data});
            });
        }
    }

    isTrainingPackeVisible(item) {
        const currentPhaseId = this.props.user.currentPhaseId;
        const trainingPackageTypes = item.trainingPackageTypes
            ? item.trainingPackageTypes[0]?.id
            : 0;
        const thematicModules = item.thematicModules
            ? item.thematicModules[0]?.id
            : 0;

        if (currentPhaseId === 1) {
            // phase_1: Training packe AND Thematic Module is set
            return trainingPackageTypes > 0 && thematicModules > 0;
        } else {
            // phase_2: (Training packe AND Thematic Module is set) OR Training pake === 4 (First Steps)
            return (
                (trainingPackageTypes > 0 && thematicModules > 0) ||
                trainingPackageTypes === 4
            );
        }
    }

    isThematicModuleVisible(item) {
        const currentPhaseId = this.props.user.currentPhaseId;
        const trainingPackageTypes = item.trainingPackageTypes
            ? item.trainingPackageTypes[0]?.id
            : 0;
        const thematicModules = item.thematicModules
            ? item.thematicModules[0]?.id
            : 0;

        if (currentPhaseId === 1) {
            // phase_1: Training packe AND Thematic Module is set
            return trainingPackageTypes > 0 && thematicModules > 0;
        } else {
            // phase_2: Training pake !== 4 (First Steps)  AND Thematic Module is set
            return trainingPackageTypes !== 4 && thematicModules > 0;
        }
    }

    setEventStats(item) {
        if (!item.genderDistribution) {
            return;
        }

        genderDistribution.datasets[0].data = item.genderDistribution.map(
            (data) => data[1]
        );
        genderDistribution.labels.forEach((label, i) => {
            const dist = item.genderDistribution.find(
                (x) => x[0].toLowerCase() == label.toLowerCase()
            );
            genderDistribution.datasets[0].data[i] = dist ? dist[1] : 0;
        });

        if (window.NodeList && !NodeList.prototype.forEach) {
            NodeList.prototype.forEach = function (callback, thisArg) {
                thisArg = thisArg || window;
                for (var i = 0; i < this.length; i++) {
                    callback.call(thisArg, this[i], i, this);
                }
            };
        }

        [
            [item.employerLevelDistribution, employerLevelDistribution],
            [item.employerTypeDistribution, employerTypeDistribution],
        ].forEach((element) => {
            let [dataset, donut] = element;
            donut.labels = [];
            donut.datasets[0].data = [];
            donut.datasets[0].backgroundColor = [];
            donut.datasets[0].borderColor = [];
            each(dataset, (data) => {
                const [label, value] = data;
                donut.labels.push(label.en);
                donut.datasets[0].data.push(value);
                let color = this.dynamicColor();
                color = `${color[0]},${color[1]},${color[2]}`;
                donut.datasets[0].backgroundColor.push(`rgba(${color},0.2)`);
                donut.datasets[0].borderColor.push(`rgba(${color},1)`);
            });
        });
    }

    async getData(id) {
        const data = await fetchData("get", `/ul-events/${id}`);
        this.onItemLoad(data[0]);
    }

    async checkSurveyExists(item) {
        if (!item.survey) {
            return;
        }
        const data = await fetchData("get", `/ul-events/${item.id}/survey_check`);
        if (data[0] && data[0].exist) {
            return;
        }
        item.survey = null;
        item.surveyStatisticUrl = null;
        this.setState({item: item});
    }

    handleDelete = async (id) => {
        const {lang} = this.props;
        if (
            window.confirm(
                `${I18n[lang].common.deleteConfirm} ${I18n[lang].events.event} ${id}?`
            )
        ) {
            await fetchData("delete", `/ul-events/${id}`);
            this.setState({open: false, loading: false, item: null});
        }
    };

    handleClose = () => {
        this.setState({open: false});
        this.renderRedirect();
    };

    renderRedirect = () => {
        if (!this.state.open) {
            return <Redirect to="/events"/>;
        }
    };

    createSurvey = (item) => {
        return async (e) => {
            e.preventDefault();
            if (!this.state.createSurveyProcessing) {
                this.setState({createSurveyProcessing: true});
                const data = await fetchData("put", `/ul-events/${item.id}`, {
                    enableCreateSurvey: true,
                });
                item.survey = data[0].survey;
                this.setState({item: item, createSurveyProcessing: false});
            }
        };
    };

    handlePDFDownload = async (fileName) => {
        if (this.state[fileName]) {
            const withLang = this.props.lang ? "?lang=" + this.props.lang : "";
            this.setState({[fileName]: false});
            await downloadFile(
                `/ul-events/${this.state.item.id}/${fileName}.pdf${withLang}`
            );
            this.setState({[fileName]: true});
        }
    };

    handlePreregDownload = async () => {
        if (this.state.listPrereg) {
            this.setState({listPrereg: false});
            await downloadFile(`/ul-events/${this.state.item.id}/enrollments.xlsx`);
            this.setState({listPrereg: true});
        }
    };

    handleDownload = (file) => {
        return async (e) => {
            e.preventDefault();
            downloadFile(file);
        };
    };

    handleImportParticipantsClick = () => {
        this.fileInputRef.current?.click();
    };

    handleImportParticipantsChange = async () => {
        if (this.fileInputRef.current?.files?.length === 0) {
            return;
        }

        const eventId = this.state.item.id;
        const {eventStore} = this.context;
        try {
            await eventStore.uploadParticipants(
                eventId,
                this.fileInputRef.current.files[0]
            );
            this.getData(eventId);

            if (eventStore.importErrors.length === 0) {
                notify(
                    `File was uploaded successfully. Added ${eventStore.importedRows} row(s).`
                );
            }
        } catch {
            notify("Failed to upload data to server.");
        }
    };

    handleParticipantTemplateDownload = async () => {
        await this.context.eventStore.downloadParticipantsTemplate();
    };

    handleSwitchPreregistrationState = async () => {
        const {item} = this.state;
        const result = await this.context.eventStore.switchPreregistrationState(
            item.id,
            !item.enrollmentEnabled
        );
        this.setState({item: result});
    };

    handleCopyToClipboard = async () => {
        const {item} = this.state;
        const {origin} = window.location;
        const path = preregistrationRoute.replace(":id", item.id);
        try {
            await navigator.clipboard.writeText(`${origin}${path}`);
            notify(
                "Preregistration URL for this event has been copied to clipboard."
            );
        } catch (ex) {
            notify("Sorry, Your browser doesn't support this feature.");
        }
    };

    handleDeletePreregParticipant = async (id) => {
        const {langStore, eventStore} = this.context;
        const confirmed = window.confirm(`${langStore.common.deleteConfirm}?`);
        if (confirmed) {
            try {
                await eventStore.deletePreregParticipant(id);
                this.getData(this.state.item.id);
            } catch (ex) {
                notify("Unknown server error.");
            }
        }
    };

    normalizeMediaLink = (item) => {
        let link = {};
        const url_regexp = /^(https?|ftp)\:\/\//;
        const domain_regexp =
            /^(www\.)?[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}(\/)?/;
        let url = item.url.trim();
        let title = item.title.trim();
        if (url.length && title.length) {
            if (!url.match(url_regexp) && url.match(domain_regexp)) {
                url = "http://" + url;
            } else if (title.match(url_regexp)) {
                let title1 = title;
                title = url;
                url = title1;
            } else if (!url.match(url_regexp)) {
                return "";
            }
            link.url = url;
            link.title = title;
        }
        if (link.url) {
            return (
                <>
                    <a href={link.url} target="_blank">
                        {link.title}
                    </a>
                    <br/>
                </>
            );
        } else {
            return "";
        }
    };

    getHromadasAmount = (item) => {
        let hromadasAmout = 0;
        item.numberOfParticipantsByCommunity &&
        Object.keys(item.numberOfParticipantsByCommunity).map((community) => {
            hromadasAmout += item.numberOfParticipantsByCommunity[community].length;
        });
        return hromadasAmout;
    };

    renderSurveyButtons = (item, classes, lang) => {
        if (
            this.props.user &&
            this.props.user.authorities.indexOf("ROLE_GUEST") >= 0
        ) {
            return "";
        }
        if (item.survey) {
            return (
                <>
                    <a href={item.survey} target="_blank">
                        <GIZButton
                            color="primary"
                            className={classes.buttonYellow}
                            icon="GoTo"
                        >
                            {I18n[lang].events.goToSurvey}
                        </GIZButton>
                    </a>
                    <Link
                        to="#"
                        className={classNames(classes.button, {
                            disabled: !this.state.survey_blank,
                        })}
                        onClick={() => this.handlePDFDownload("survey_blank")}
                    >
                        <GIZButton
                            color="primary"
                            className={classes.buttonYellow}
                            icon="CloudDownload"
                        >
                            {I18n[lang].events.getEvaluationForm}
                        </GIZButton>
                    </Link>
                    <a href={item.surveyStatisticUrl} target="_blank">
                        <GIZButton
                            color="primary"
                            className={classes.buttonYellow}
                            icon="Equalizer"
                        >
                            {I18n[lang].events.viewStatistics}
                        </GIZButton>
                    </a>
                </>
            );
        } else {
            return (
                <Link
                    to="#"
                    className={classNames(classes.button, {
                        disabled: this.state.createSurveyProcessing,
                    })}
                    onClick={this.createSurvey(item)}
                >
                    <GIZButton
                        color="primary"
                        className={classes.buttonYellow}
                        icon="TaskAlt"
                    >
                        {I18n[lang].events.createSurvey}
                    </GIZButton>
                </Link>
            );
        }
    };

    handleFullScreen = () => {
        if (this.state.photoFullBase64) {
            this.setState({isPhotoFull: true});
        } else {
            fetchBase64(this.state.item.eventFotoUrl, (data) => {
                this.setState({
                    isPhotoFull: true,
                    photoFullBase64: data,
                });
            });
        }
    };

    closeFull = (event) => {
        event.preventDefault();
        this.setState({isPhotoFull: false});
    };

    async deleteParticipantFromEvent(participant) {
        const eventId = this.state.item.id;
        const {lang} = this.props;
        if (
            window.confirm(
                `${I18n[lang].common.deleteConfirm} ${I18n[lang].participants.participant} ${participant.id} ${I18n[lang].common.fromEvent}?`
            )
        ) {
            await fetchData(
                "delete",
                `/participants/disconnect/${participant.id}/${eventId}`
            );
            this.getData(eventId);
        }
    }

    showTrainers() {
      return [3, 6, 7, 10].indexOf(this.state.item.format.id) > -1;
    } 

    showFacilitators() {
        return [4, 5, 8, 9].indexOf(this.state.item.format.id) > -1;
    }

    showLimeSurvey() {
        return [2, 6, 8, 8, 9].indexOf(this.state.item.format.id) === -1;
    }

    render() {
        const {classes, lang, user} = this.props;
        const {item, isPhotoFull, loading, photoFullBase64, photoPreviewBase64} =
            this.state;
        const {langStore, eventStore} = this.context;

        if ((!item || !item.id) && loading) {
            return "";
        }
        if (!loading && !item) {
            return this.renderRedirect();
        }
        const catCaptions = categoryCaptions(user);

        return (
            <>
                {this.state.loading && <Loader/>}

                {this.renderRedirect()}

                {isPhotoFull && photoFullBase64 && (
                    <div className={classes.imageFullScreen}>
                        <img src={photoFullBase64} alt=""/>
                        <a href="#" onClick={this.closeFull}>
                            X
                        </a>
                    </div>
                )}

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.open}
                    onClose={this.handleClose}
                >
                    <div className={classes.modal}>
                        <a className="close" onClick={this.handleClose}>
                            <Close/>
                        </a>
                        <Grid container spacing={24} alignItems="center">
                            <Grid item xs={12}>
                                <Typography
                                    gutterBottom
                                    variant="h2"
                                    component="h2"
                                    style={{color: "#fdbb2d"}}
                                    className={classes.titleYellow}
                                >
                                    {I18n[lang].events.event} {item.id}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={24}
                            alignItems="center"
                            className={classes.buttons}
                        >
                            <Grid item xs={12}>
                                {!item.restrictedView && !item.readOnly && (
                                    <>
                                        <Link to={`/events/edit/${item.id}`}>
                                            <GIZButton color="primary" icon="Edit">
                                                {I18n[lang].common.edit}
                                            </GIZButton>
                                        </Link>
                                        <GIZButton
                                            color="secondary"
                                            icon="Clear"
                                            onClick={this.handleDelete.bind(this, item.id)}
                                        >
                                            {I18n[lang].common.delete}
                                        </GIZButton>
                                        <Link to={`/participants/create?eventId=${item.id}`}>
                                            <GIZButton color="tertiary" icon="Add">
                                                {I18n[lang].events.addParticipant}
                                            </GIZButton>
                                        </Link>
                                        <Hidden only={['xs', 'sm']}>
                                            <input
                                                ref={this.fileInputRef}
                                                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                type="file"
                                                style={{display: "none"}}
                                                onChange={this.handleImportParticipantsChange}
                                            />
                                            <GIZButton
                                                color="tertiary"
                                                icon="ImportExport"
                                                disabled={eventStore.uploadParticipantsInProgress}
                                                onClick={this.handleImportParticipantsClick}
                                            >
                                                {I18n[lang].events.importParticipants}
                                            </GIZButton>
                                            <GIZButton
                                                color="quaternary"
                                                icon="CloudDownload"
                                                disabled={eventStore.downloadTemplateInProgress}
                                                onClick={this.handleParticipantTemplateDownload}
                                            >
                                                {I18n[lang].events.downloadParticipantsTemplate}
                                            </GIZButton>
                                            <Modal
                                                aria-labelledby="error-modal-title"
                                                aria-describedby="error-modal-description"
                                                open={eventStore.importErrors.length > 0}
                                                onClose={eventStore.clearImportErrors}
                                            >
                                                <div className={classes.errorModal}>
                                                    <Typography
                                                        variant="h3"
                                                        gutterBottom
                                                        id="error-modal-title"
                                                    >
                                                        Import errors
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        component="div"
                                                        id="error-modal-description"
                                                    >
                                                        <ul>
                                                            {eventStore.importErrors.map(([row, error]) => (
                                                                <li key={row}>
                                                                    Row {row} - {error}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </Typography>
                                                </div>
                                            </Modal>
                                        </Hidden>
                                    </>
                                )}
                                {!item.restrictedView && (
                                    <>
                                        <GIZButton
                                            color="quaternary"
                                            icon="List"
                                            onClick={this.handlePDFDownload.bind(
                                                this,
                                                "list_participants"
                                            )}
                                            disabled={!this.state.list_participants}
                                        >
                                            {langStore.events.printList}
                                        </GIZButton>
                                        <Hidden only={['xs', 'sm']}>
                                            <GIZButton
                                                color="quaternary"
                                                icon="Reorder"
                                                onClick={this.handlePDFDownload.bind(
                                                    this,
                                                    "list_empty_participants"
                                                )}
                                                disabled={!this.state.list_empty_participants}
                                            >
                                                {langStore.events.printEmptyList}
                                            </GIZButton>
                                        </Hidden>
                                    </>
                                )}
                                <GIZButton
                                    color="quaternary"
                                    icon="Info"
                                    onClick={this.handlePDFDownload.bind(this, "print_info")}
                                    disabled={!this.state.print_info}
                                >
                                    {langStore.events.printEventInfo}
                                </GIZButton>
                                {!item.restrictedView &&
                                    this.showLimeSurvey() &&
                                    this.renderSurveyButtons(item, classes, lang)}
                                {!item.restrictedView && (
                                    <>
                                        <GIZSwitch
                                            label={
                                                langStore.events[
                                                    item.enrollmentEnabled
                                                        ? "turnOffPreregistration"
                                                        : "turnOnPreregistration"
                                                    ]
                                            }
                                            checked={item.enrollmentEnabled}
                                            onChange={this.handleSwitchPreregistrationState}
                                            className={classes.switch}
                                        />
                                        {item.enrollmentEnabled && (
                                            <>
                                                <GIZButton
                                                    color="primary"
                                                    icon="ContentCopy"
                                                    onClick={this.handleCopyToClipboard}
                                                >
                                                    {langStore.events.copyToClipboard}
                                                </GIZButton>
                                                <Hidden only={['xs', 'sm']}>
                                                    <GIZButton
                                                        color="quaternary"
                                                        icon="List"
                                                        onClick={this.handlePreregDownload}
                                                        disabled={!this.state.listPrereg}
                                                    >
                                                        {langStore.events.printPreregList}
                                                    </GIZButton>
                                                </Hidden>
                                            </>
                                        )}
                                    </>
                                )}
                            </Grid>
                        </Grid>
                        <Typography className={classes.data} component="div">
                            <Grid container spacing={24} alignItems="flex-start">
                                <Grid item xs={12} sm={6}>
                                    <h4>{I18n[lang].events.nameEn}</h4>
                                    <p>{item.nameEn}</p>
                                    <h4>{I18n[lang].events.descEn}</h4>
                                    <p>{item.descEn}</p>
                                    <h4>{String(I18n[lang].events.startDate)}</h4>
                                    <p>{String(item.startDate)}</p>
                                    <h4>{I18n[lang].events.internalFeedback}</h4>
                                    <p>{item.internalFeedback}</p>
                                    <h4>{I18n[lang].events.expectedParticipants}</h4>
                                    <p>{item.expectedNumberOfParticipants}</p>
                                    <h4>{I18n[lang].events.actualParticipants}</h4>
                                    <p>{item.actualNumberOfParticipants || 0}</p>
                                    <h4>{I18n[lang].events.eventReport}</h4>
                                    <p>
                                        {item.eventReportUrl ? (
                                            <Link
                                                to="#"
                                                className={classes.linkReport}
                                                onClick={this.handleDownload(item.eventReportUrl)}
                                            >
                                                <InsertDriveFile/>
                                                {item.eventReportSize}
                                            </Link>
                                        ) : (
                                            I18n[lang].events.reportMissing
                                        )}
                                    </p>
                                    <h4>{I18n[lang].events.region}</h4>
                                    <p>{lang === "Ukr" ? item.oblastUa : item.oblastEn}</p>
                                    <h4>{I18n[lang].events.organizers}</h4>
                                    <p>
                                        {item.organizers &&
                                            item.organizers.map((item, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        {lang === "Ukr" ? item.ua : item.en}
                                                        <br/>
                                                    </React.Fragment>
                                                );
                                            })}
                                    </p>
                                    <h4>{I18n[lang].events.createdBy}</h4>
                                    <p>{item.ownerLogin}</p>
                                    {this.showTrainers() &&
                                        (!item.restrictedView || item.specialRights) && (
                                            <>
                                                <h4>{I18n[lang].events.trainers}</h4>
                                                <p>
                                                    {item.trainers &&
                                                        item.trainers.map((trainer, index) => {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <Link
                                                                        className={classes.blockLink}
                                                                        to={`/trainers/view/${trainer.id}`}
                                                                    >
                                                                        {trainer.name} | {trainer.nameTranscription}
                                                                    </Link>
                                                                    <br/>
                                                                    {trainer.info && trainer.info.length && (
                                                                        <>
                                      <span className={classes.textSmall}>
                                        {trainer.position} {trainer.employerName}
                                      </span>
                                                                            <br/>
                                                                        </>
                                                                    )}
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                </p>
                                            </>
                                        )}

                                    {this.showFacilitators() && (
                                        <>
                                            <h4>{I18n[lang].events.facilitators}</h4>
                                            <p>
                                                {item.facilitators &&
                                                    item.facilitators.map((facilitator, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <Link
                                                                    className={classes.blockLink}
                                                                    to={`/facilitators/view/${facilitator.id}`}
                                                                >
                                                                    {facilitator.name}
                                                                </Link>
                                                                <br/>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                            </p>
                                        </>
                                    )}

                                    <h4>{catCaptions.categories}</h4>
                                    <p>
                                        {item.categories &&
                                            item.categories.map((category, index) => {
                                                if (category.langKey === "not_applicable") {
                                                    return "";
                                                }
                                                return (
                                                    <React.Fragment key={index}>
                                                        {lang === "Ukr" ? category.ua : category.en}
                                                        <br/>
                                                    </React.Fragment>
                                                );
                                            })}
                                    </p>

                                    <h4>{I18n[lang].events.kind}</h4>
                                    <p>{item.kind}</p>

                                    <h4>{I18n[lang].events.results}</h4>
                                    <p>
                                        {item.categoryResults &&
                                            item.categoryResults.map((result, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        {result.nameEn}
                                                        <br/>
                                                    </React.Fragment>
                                                );
                                            })}
                                    </p>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <h4>{I18n[lang].events.nameUa}</h4>
                                    <p>{item.nameUa}</p>
                                    <h4>{I18n[lang].events.descUa}</h4>
                                    <p>{item.descUa}</p>
                                    <h4>{I18n[lang].events.endDate}</h4>
                                    <p>{item.endDate}</p>
                                    <h4>{I18n[lang].events.mediaReferences}</h4>
                                    <p>
                                        {item.mediaLinks &&
                                            item.mediaLinks.map((item, index) => (
                                                <React.Fragment key={index}>
                                                    {this.normalizeMediaLink(item)}
                                                </React.Fragment>
                                            ))}
                                    </p>
                                    <h4>{I18n[lang].events.fundingSources}</h4>
                                    <p>
                                        {item.fundingSources &&
                                            item.fundingSources.map((item, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        {lang === "Ukr" ? item.ua : item.en}
                                                        <br/>
                                                    </React.Fragment>
                                                );
                                            })}
                                    </p>
                                    <h4>{I18n[lang].events.eventAddress}</h4>
                                    <p>{item.eventAddress}</p>

                                    <h4>{I18n[lang].events.eventFoto}</h4>

                                    <p>
                                        {photoPreviewBase64 && (
                                            <img
                                                className={classNames(classes.image)}
                                                onClick={this.handleFullScreen}
                                                src={photoPreviewBase64}
                                                alt=""
                                            />
                                        )}
                                    </p>

                                    <h4>{I18n[lang].events.targetGroups}</h4>
                                    <p>
                                        {item.targetGroups &&
                                            item.targetGroups.map((item, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        {lang === "Ukr" ? item.ua : item.en}
                                                        <br/>
                                                    </React.Fragment>
                                                );
                                            })}
                                    </p>
                                    {item.targetLevels && item.targetLevels.length && (
                                        <>
                                            <h4>{I18n[lang].events.targetLevels}</h4>
                                            <p>
                                                {item.targetLevels.map((item, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            {lang === "Ukr" ? item.ua : item.en}
                                                            <br/>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </p>
                                        </>
                                    )}
                                    <h4>{I18n[lang].events.eventFormat}</h4>
                                    <p>{lang === "Ukr" ? item.format.ua : item.format.en}</p>

                                    <h4>{I18n[lang].events.responsiblePerson}</h4>
                                    <p>{item.responsiblePerson}</p>

                                    {this.isTrainingPackeVisible(item) &&
                                        !this.isThematicModuleVisible(item) && (
                                            <>
                                                <h4>{I18n[lang].events.thematicSupportPackage}</h4>
                                                <p>{item.trainingPackageTypes[0].nameEn}</p>
                                            </>
                                        )}

                                    {this.isTrainingPackeVisible(item) &&
                                        this.isThematicModuleVisible(item) && (
                                            <>
                                                <h4>{I18n[lang].events.thematicSupportPackage}</h4>
                                                <p>
                                                    {item.trainingPackageTypes[0].nameEn}:{" "}
                                                    {item.thematicModules[0].nameEn}
                                                </p>
                                            </>
                                        )}

                                    {item.europeActions && item.europeActions[0] && (
                                        <>
                                            <h4>{I18n[lang].events.regionalInitiatives}</h4>
                                            <p>
                                                {lang === "Ukr"
                                                    ? item.europeActions[0].nameUa
                                                    : item.europeActions[0].nameEn}
                                            </p>
                                        </>
                                    )}

                                    {(item.eventCharacters && item.eventCharacters.length && (
                                            <>
                                                <h4>{I18n[lang].events.eventCharacters}</h4>
                                                <p>
                                                    {item.eventCharacters.map((item, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                {lang === "Ukr" ? item.ua : item.en}
                                                                <br/>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </p>
                                            </>
                                        )) ||
                                        ""}
                                </Grid>
                            </Grid>
                            <Hidden only={['xs', 'sm']}>
                                {((item.participantsCount ||
                                        (item.participants && item.participants.length)) && (
                                        <div className={classes.participantsRegistered}>
                                            <Grid container spacing={24} alignItems="flex-start">
                                                <Grid item xs={12}>
                                                    <h2 className={classes.titleBlue}>
                                                        {I18n[lang].events.participantRegistered}:{" "}
                                                        {item.participantsCount || item.participants.length}
                                                    </h2>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                container
                                                spacing={24}
                                                alignItems="flex-start"
                                                className={classes.charts}
                                            >
                                                <Grid item xs={12} sm={4}>
                                                    <h4>{I18n[lang].events.genderDistribution}</h4>
                                                    <Doughnut
                                                        data={genderDistribution}
                                                        options={{legend: {display: false}}}
                                                    />
                                                </Grid>
                                                <Grid itemxs={12} sm={4}>
                                                    <h4>{I18n[lang].events.employerLevelDistribution}</h4>
                                                    <Doughnut
                                                        data={employerLevelDistribution}
                                                        options={{legend: {display: false}}}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <h4>{I18n[lang].events.employerTypeDistribution}</h4>
                                                    <Doughnut
                                                        data={employerTypeDistribution}
                                                        options={{legend: {display: false}}}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )) ||
                                    ""}
                            </Hidden>

                            <Grid container spacing={24} alignItems="flex-start">
                                <Grid item xs={12}>
                                    <h2 className={classes.titleYellow}>
                                        {I18n[lang].events.uniqueHromadas}:{" "}
                                        {this.getHromadasAmount(item)}
                                    </h2>
                                </Grid>
                            </Grid>

                            {item.numberOfParticipantsByCommunity && (


                                <Grid
                                    container
                                    spacing={24}
                                    alignItems="flex-start"
                                    className={classes.hromada}
                                >
                                    {Object.keys(item.numberOfParticipantsByCommunity).map(
                                        (community, index) => {
                                            return (
                                                <Grid item xs={12} md={4} key={index}>
                                                    <h4>{community}</h4>
                                                    <ResponsiveTable lang={this.props.lang}
                                                                     i18n={I18n[this.props.lang].events}
                                                                     classes={classes}
                                                                     items={item.numberOfParticipantsByCommunity[community]}
                                                                     columns={[
                                                                         {
                                                                             title: I18n[lang].events.location,
                                                                             displayFunc: (item) => (langStore.isEng ? item.en : item.ua)
                                                                         },
                                                                         {
                                                                             id: "count",
                                                                             title: I18n[lang].events.participants
                                                                         }
                                                                     ]}
                                                    />
                                                </Grid>
                                            );
                                        }
                                    )}
                                </Grid>
                            )}

                            {(!item.restrictedView || item.specialRights) && (
                                <>


                                    <Grid container spacing={24} alignItems="flex-start">
                                        <Grid item xs={12}>
                                            <h2 className={classes.titleBlue}>
                                                {I18n[lang].events.participants}
                                            </h2>
                                        </Grid>
                                    </Grid>
                                    <br/>


                                    <ResponsiveTable classes={classes}
                                                     lang={this.props.lang} i18n={I18n[this.props.lang].communities}
                                                     items={item.participants &&
                                                         orderBy(
                                                             item.participants,
                                                             lang === "Eng" ? "nameTranscription" : "name",
                                                             "asc"
                                                         )}
                                                     columns={[
                                                         {
                                                             title: I18n[lang].events.name,
                                                             displayFunc: (row) => (<a
                                                                 target="_blank"
                                                                 rel="noreferrer"
                                                                 href={`/participants/view/${row.id}`}
                                                             >
                                                                 {lang == "Eng"
                                                                     ? row.nameTranscription
                                                                     : row.name}
                                                             </a>)
                                                         },
                                                         {
                                                             title: I18n[lang].events.gender,
                                                             displayFunc: (row) => (I18n[lang].common[row.gender] ?? "")
                                                         },
                                                         {id: "cellPhone"},
                                                         {
                                                             title: I18n[lang].events.employerLevel,
                                                             displayFunc: (row) => (row.communityId ? (
                                                                 <a
                                                                     href={`/communities/view/${row.communityId}`}
                                                                 >
                                                                     {langStore.isEng
                                                                         ? row.communityNameEn
                                                                         : row.communityNameUa}
                                                                 </a>
                                                             ) : lang == "Eng" ? (
                                                                 row.employerLevelEn
                                                             ) : (
                                                                 row.employerLevelUa
                                                             ))
                                                         },
                                                         {
                                                             title: I18n[lang].events.employerType,
                                                             displayFunc: (row) => (lang == "Eng"
                                                                 ? row.employerTypeEn
                                                                 : row.employerTypeUa)
                                                         },
                                                     ]}

                                                     renderActions={(row) => (<>
                                                         {!item.restrictedView && (
                                                             <GIZButton
                                                                 icon="Delete"
                                                                 color="secondary"
                                                                 className={classes.participantAction}
                                                                 onClick={() => this.deleteParticipantFromEvent(row)}
                                                             >
                                                                 {langStore.common.delete}
                                                             </GIZButton>)}
                                                         </>)}

                                    />

                                    <br/>


                                </>
                            )}

                            {!item.restrictedView && item.prelimParticipants?.length > 0 && (
                                <>
                                    <Grid container spacing={24} alignItems="flex-start">
                                        <Grid item xs={12}>
                                            <h2 className={classes.titleBlue}>
                                                {langStore.events.preregisteredParticipants}
                                            </h2>
                                        </Grid>
                                    </Grid>
                                    <br/>

                                    <ResponsiveTable classes={classes}
                                                     lang={this.props.lang} i18n={I18n[this.props.lang].communities}
                                                     items={item.prelimParticipants &&
                                                         orderBy(
                                                             item.prelimParticipants,
                                                             lang === "Eng" ? "nameTranscription" : "name",
                                                             "asc"
                                                         )}
                                                     columns={[
                                                         {
                                                             title: I18n[lang].events.name,
                                                             displayFunc: (row) => (langStore.isEng ? row.nameEn : row.nameUa)
                                                         },
                                                         {id: "cellPhone"},
                                                         {
                                                             id: "gender",
                                                             displayFunc: (row) => (langStore.common[row.gender] ?? "")
                                                         }]}
                                                     renderActions={(row) => (<><Link
                                                         to={`/participants/create?eventId=${item.id}&preregId=${row.id}`}
                                                     >
                                                         <GIZButton
                                                             icon="Add"
                                                             color="primary"
                                                             className={classes.participantAction}
                                                         >
                                                             {langStore.common.add}
                                                         </GIZButton>
                                                     </Link>
                                                         <GIZButton
                                                             icon="Delete"
                                                             color="secondary"
                                                             className={classes.participantAction}
                                                             onClick={this.handleDeletePreregParticipant.bind(
                                                                 this,
                                                                 row.id
                                                             )}
                                                         >
                                                             {langStore.common.delete}
                                                         </GIZButton></>)}
                                    />

                                </>
                            )}
                        </Typography>
                    </div>
                </Modal>
            </>
        );
    }
}

export default withRoot(withStyles(styles)(observer(ItemView)));
